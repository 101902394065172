import react, { useEffect, useState } from "react";
import './text-container.scss';


interface IUser {
    heading: string;
    value:string,
    id:number,
    key:number
  }


function Textcontainer(props:any){

    const [useData, setData]= useState<IUser>()
    useEffect(() => { setData(props?.data); },[props?.data]);

return(<>

<div className="d-flex text-container pb-2">
    <span>{useData?.heading}</span>
    <span className="ps-2">{useData?.value}</span>
</div>



</>)

}

export default Textcontainer;