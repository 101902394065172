import React from "react";
import './resume.scss';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Chip } from "primereact/chip";

function Resume() {

    const events = [
        { status: 'Netprophets Cyber Works', location:'Noida', position:"Software Enigneer" , from:'Aug 2021 to Current'  },
        { status: 'Source Code Solutions', location:'Gurugram', position:"Web Designer and Developer" , from:'Nov 2019 to Aug 2021' },
        { status: 'Tech Cure Solution', location:'Gurugram', position:"Web Designer and Developer" , from:'Feb 2019 to Oct 2019'  },
        { status: 'Foundlay Tecnologies', location:'Noida', position:"Web Designer Trainee" , from:'Aug 2018 to Jan 2019'  }
    ];

    const codingSkills =[
        {skills: 'HTML/HTML5'},
        {skills: 'CSS'},
        {skills: 'SCSS'},
        {skills: 'Javascript'},
        {skills: 'Angular'},
        {skills: 'React'},
        {skills: 'React Native'}
    ]

    const versionSkills =[
        {skills: 'Git'},
        {skills: 'SVN'}
    ]

    const cmsSkills =[
        {skills: 'Wordpress'},
        {skills: 'Shopify'},
        {skills: 'Directus'},
        {skills: 'Strapi'},
    ]

    const customizedContent = (item:any) => {
        return (
            <Card title={item.status} subTitle={item.position}>
                <p>{item?.from}</p>    
            </Card>
        );
    };


    const coddongData =() =>{
        return(
            codingSkills.map(items =>  <Chip label={items.skills} className="m-2" />)
            )   
    }

    const vesrionData =() =>{
           return(
            versionSkills.map(items =>  <Chip label={items.skills} className="m-2" />)
            ) 
    }

    const cmsData =() =>{
        return(
            cmsSkills.map(items =>  <Chip label={items.skills} className="m-2" />)
         ) 
 }





    return (
        <div className="main-container  py-3 px-2 p-lg-5">

            <div className="container">
                <h2 className="page-headings">Resume</h2>

                <div className="row mt-5">
               
                    <div className="col-12 col-lg-8">
                    <h2 className="page-headings pb-4"> Experience</h2>
                    <div className="d-none d-lg-block"><Timeline value={events} content={(item) => customizedContent(item)} align="alternate" /></div>

                        <div className="d-block d-lg-none"><Timeline value={events} content={(item) => customizedContent(item)} align="left"/></div>

                    </div>
                    <div className="col-12 col-lg-4">
                    <h2 className="page-headings pb-4"> Coding <span>Skills</span></h2>
                       {coddongData()}

                       <h2 className="page-headings pb-4 pt-5"> CMS</h2>
                       {cmsData()}

                       <h2 className="page-headings pb-4 pt-5"> Version <span>Control</span></h2>
                       {vesrionData()}

                   
                       
                 
                    </div>
                 


                </div>





            </div>

        </div>
    )

}


export default Resume;