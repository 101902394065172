import React, { useEffect } from "react"
import { Card } from 'primereact/card';
import './contact.scss'
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Lang, useFormInputValidation } from "react-form-input-validation";
import enviroment from "../../auth/enviroment";



function Contact() {

    const [fields, errors, form]:any = useFormInputValidation(
        {
          full_name: "",
          email: "",
          subject: "",
          message: "",
        },
        {
          full_name: "required|max:50",
          email: "required|email",
          subject: "required|max:50",
          message: "required|max:200"
        }
      );
      const onSubmit = async (event:any) => {
        console.log(form.validate(event))
        const isValid = await form.validate(event);

        if (isValid) {

           
            // http://ritinresume.club/api/contact-form-7/v1/contact-forms/[contact-form-7 id="92cb046" title="Contact form 1"]/feedback
            enviroment.post('form_submit',fields).then((response) => {
                if (response) {
                    
                    // setIsLoading(false)
                }
                else {
                    alert('not-found')
                    // setIsLoading(false)
                }
            }).catch((error) => {
                // setIsLoading(false)
            })
          console.log(fields);
        }
      };



      useEffect(() => {
        form.registerAsync("username_available", function (
          username:any,
          attribute:any,
          req:any,
          passes:any,
        ) {
          setTimeout(() => {
            if (username === "foo")
              passes(false, "Username has already been taken.");
            // if username is not available
            else passes();
          }, 1000);
        });
      }, []);
    
      form.useLang(Lang.en);
    

    return (
        <div className="main-container  py-3 px-2 p-lg-5">

            <div className="container">
                <h2 className="page-headings">Contact</h2>

                <div className="row">

                    <div className="col-12 col-lg-5">
                        <Card className="left-card d-flex justify-content-center align-items-center mt-4">
                            <i className="lnr lnr-map-marker"></i>
                            <p className="m-0">
                                Delhi
                            </p>
                        </Card>
                        <Card className="left-card d-flex justify-content-center align-items-center mt-4">
                            <i className="lnr lnr-phone-handset"></i>
                            <p className="m-0">
                            <a href="tel:+917838298988">+91-7838298988</a>
                                
                            </p>
                        </Card>
                        <Card className="left-card d-flex justify-content-center align-items-center mt-4">
                            <i className="lnr lnr-envelope"></i>
                            <p className="m-0">
                                <a href="mailto:ritin.nijhawan@gmail.com">ritin.nijhawan@gmail.com</a>
                            </p>
                        </Card>

                    </div>

                    <div className="col-12 col-lg-7 mt-4">



                        <form   onSubmit={onSubmit}>
                            <div className="row">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.155919697057!2d77.17701737590973!3d28.714885975619822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d01f6fa08befb%3A0x1e561be83163bb17!2sGuru%20Nanak%20Rd%2C%20Kewal%20Park%2C%20Azadpur%2C%20Delhi%2C%20110009!5e0!3m2!1sen!2sin!4v1708064694057!5m2!1sen!2sin" width="600" height="100"   loading="lazy" className="mb-2"></iframe>
                            <h2 className="page-headings mt-2">How Can <span>I Help You?</span></h2>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex flex-column">
                                        <InputText id="full_name" name="full_name"   placeholder="Full Name"  onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}   className="mt-3" />
                                        <label className="error"> {errors.full_name ? errors.full_name : ""} </label>
                                        <InputText id="email" name="email" placeholder="Email"  onBlur={form.handleBlurEvent}  onChange={form.handleChangeEvent}/>
                                        <label className="error"> {errors.email ? errors.email : ""} </label>
                                        <InputText id="subject" name="subject" placeholder="Subject"  onBlur={form.handleBlurEvent}  onChange={form.handleChangeEvent}/>
                                        <label className="error"> {errors.subject ? errors.subject : ""} </label>
                                        <Button label="Submit" type="submit" icon="pi pi-check" className="d-none d-lg-block" />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <InputTextarea name="message" placeholder="Enter Message" rows={6} cols={40} className="mt-3"  onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} />
                                    <label className="error"> {errors.message ? errors.message : ""} </label>
                                </div>
                             
                                <Button label="Submit" type="submit" icon="pi pi-check" className="d-block d-lg-none mt-2" />


                            </div>
                        </form>
                    </div>


                </div>





            </div>

        </div>
    )

}
export default Contact