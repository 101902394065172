import React from "react";
import load from '../../assets/images/ball.gif';
import './loader.scss'


function Loader(props: any) {

return(

    <div className="loader-container d-flex justify-content-center align-items-center" >
    <img src={load}  alt="loader"/>
    
    </div >

)



}

export default Loader;