import React, { useEffect, useState } from "react";
import './portfolio.scss';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import enviroment from "../../auth/enviroment";
import axios from "axios";
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import Loader from "../../shared/loader/loader";
import { Masonry } from 'react-masonry'
function Portfolio() {

    const [allData, setAllData] = useState(Array)
    const [filteredData, setFilterData] = useState(Array)
    const [category, setCategory] = useState()
    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getCategories()


    }, []);

    const getCategories = () => {
        setIsLoading(true)
        enviroment.get('categories').then((response) => {
            if (response.data) {

                setCategory(response.data)
                getPortfolio()
                
            }
            else {
                alert('not-found')
                setIsLoading(false)
                
            }
        }).catch((error) => {
            setIsLoading(false)
        })


    }



    const getPortfolio = () => {
        setActiveIndex(0)
        enviroment.get('job-portfolio?per_page=100').then((response) => {
            if (response.data) {
                setAllData(response.data)
                setFilterData(response.data)
                
                setIsLoading(false)
            }
            else {
                alert('not-found')
                setIsLoading(false)
                
            }
        }).catch((error) => {
            setIsLoading(false)
            
        })


    }

    const filterCategory = (slug: any) => {
        let cat: any = structuredClone(category)
        return cat.filter((res: any) => res.id == slug)[0]?.name
    }


    const filterOnTabChange = (id:any) =>{

        let index = id.index
        setActiveIndex(index)
        let unFilterData = structuredClone(allData)
        if(index == 0){setFilterData(unFilterData) } //All
        else  if(index == 1){setFilterData(filterDataAccCategories(unFilterData, 3))   } //3 Angular
        else if(index == 2){setFilterData(filterDataAccCategories(unFilterData, 4))   } //4 React
        else if(index == 3){setFilterData(filterDataAccCategories(unFilterData, 6))   } //6 Shopify
        else if(index == 4) {setFilterData(filterDataAccCategories(unFilterData, 5))   }  // 5 wordpress
        else if(index == 5){setFilterData(filterDataAccCategories(unFilterData, 7))   } //7 HTML
    }


    const filterDataAccCategories = (data:Array<any>, id:number) =>{
        return data.filter((res: any) => res.categories[0] == id)
    }





    return (
        <div className="main-container  py-3 px-2 p-lg-5">

            <div className="container">
                <h2 className="page-headings">Portfolio</h2>
               {isLoading ? <Loader />  : 
        
                <TabView className="mt-5" activeIndex={activeIndex} onTabChange={(e) => filterOnTabChange(e)}>
                    <TabPanel header="All" key={7}>
                        <div className="row">
                        <Masonry>
                            {filteredData.map((items: any, index: number) =>
                             <div className="col-12 col-md-6 col-xl-4 mb-2 px-2" key={items?.id}>
                              <Card title={items.title.rendered} subTitle={filterCategory(items?.categories[0])} className="md:w-25rem " >
                                       <p className="m-0" dangerouslySetInnerHTML={{ __html: items.excerpt.rendered }} key={index} />
                                     <div className="d-flex mt-3"><Button label="Link" onClick={() => window.open(items.acf.link, '_blank')} /></div>
                                 </Card>
                                 </div>
                             


                                // <div className="col-12 col-md-6 col-xl-4" key={items?.id}>
                                //     <Card title={items.title.rendered} subTitle={filterCategory(items?.categories[0])} className="md:w-25rem" >
                                //         <p className="m-0" dangerouslySetInnerHTML={{ __html: items.excerpt.rendered }} key={index} />
                                //         <div className="d-flex mt-3"><Button label="Link" onClick={() => window.open(items.acf.link, '_blank')} /></div>
                                //     </Card>
                                // </div>
                            )}
                              </Masonry>
                        </div>
                    </TabPanel>
                    <TabPanel header="Angular" key={2}>
                        <div className="row">
                        <Masonry>
                            {filteredData.map((items: any, index: number) =>
                                <div className="col-12 col-md-6 col-lg-4 mb-2 px-2" key={items?.id}>
                                    <Card title={items.title.rendered} subTitle={filterCategory(items?.categories[0])} className="md:w-25rem" >
                                        <p className="m-0" dangerouslySetInnerHTML={{ __html: items.excerpt.rendered }} key={index}>

                                        </p>
                                        <div className="d-flex mt-3"><Button label="Link" onClick={() => window.open(items.acf.link, '_blank')} /></div>

                                    </Card>
                                </div>
                            )}
                            </Masonry>
                        </div>
                    </TabPanel>
                    <TabPanel header="React"  key={3}>
                    <div className="row">
                    <Masonry>
                            {filteredData.map((items: any, index: number) =>
                                <div className="col-12 col-md-6 col-lg-4 mb-2 px-2" key={items?.id}>
                                    <Card title={items.title.rendered} subTitle={filterCategory(items?.categories[0])} className="md:w-25rem" >
                                        <p className="m-0" dangerouslySetInnerHTML={{ __html: items.excerpt.rendered }} key={index}>

                                        </p>
                                        <div className="d-flex mt-3"><Button label="Link" onClick={() => window.open(items.acf.link, '_blank')} /></div>

                                    </Card>
                                </div>
                            )}
                            </Masonry>
                        </div>
                    </TabPanel>
                    <TabPanel header="Shopify"  key={4}>
                    <div className="row">
                    <Masonry>
                            {filteredData.map((items: any, index: number) =>
                                <div className="col-12 col-md-6 col-lg-4 mb-2 px-2" key={items?.id}>
                                    <Card title={items.title.rendered} subTitle={filterCategory(items?.categories[0])} className="md:w-25rem" >
                                        <p className="m-0" dangerouslySetInnerHTML={{ __html: items.excerpt.rendered }} key={index}>

                                        </p>
                                        <div className="d-flex mt-3"><Button label="Link" onClick={() => window.open(items.acf.link, '_blank')} /></div>

                                    </Card>
                                </div>
                            )}
                            </Masonry>
                        </div>
                    </TabPanel>

                    <TabPanel header="Wordpress"  key={5}>
                    <div className="row">
                    <Masonry>
                            {filteredData.map((items: any, index: number) =>
                                <div className="col-12 col-md-6 col-lg-4 mb-2 px-2" key={items?.id}>
                                    <Card title={items.title.rendered} subTitle={filterCategory(items?.categories[0])} className="md:w-25rem" >
                                        <p className="m-0" dangerouslySetInnerHTML={{ __html: items.excerpt.rendered }} key={index}>

                                        </p>
                                        <div className="d-flex mt-3"><Button label="Link" onClick={() => window.open(items.acf.link, '_blank')} /></div>

                                    </Card>
                                </div>
                            )}
                            </Masonry>
                        </div>
                    </TabPanel>
                    <TabPanel header="HTML"  key={6}>
                    <div className="row">
                    <Masonry>
                            {filteredData.map((items: any, index: number) =>
                                <div className="col-12 col-md-6 col-lg-4 mb-2 px-2" key={items?.id}>
                                    <Card title={items.title.rendered} subTitle={filterCategory(items?.categories[0])} className="md:w-25rem" >
                                        <p className="m-0" dangerouslySetInnerHTML={{ __html: items.excerpt.rendered }} key={index}>

                                        </p>
                                        <div className="d-flex mt-3"><Button label="Link" onClick={() => window.open(items.acf.link, '_blank')} /></div>

                                    </Card>
                                </div>
                            )}
                            </Masonry>
                        </div>
                    </TabPanel>
                </TabView>
                } 


            </div>


      

        </div>
    )
}

export default Portfolio