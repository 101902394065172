import React, { useEffect, useRef, useState } from "react";
import './header.scss';
import Mainphoto from '../../assets/images/main_photo.jpg';
import PersonPath from '../../assets/images/icons/person.svg';
import Resume from '../../assets/images/icons/resume.svg';
import Portfolio from '../../assets/images/icons/portfolio.svg';
import Contact from '../../assets/images/icons/contact.svg';
import { Link } from "react-router-dom";

import { Button } from 'primereact/button';
import enviroment from "../../auth/enviroment";
import { error } from "console";
import { type } from "os";

function Header(props: any) {
    const child1 = useRef(null);
    const [headerItems, setHeadeItems] = useState<any>()
    const [profile, setProfile] = useState<any>()
    const [download, setDownlaod] = useState()


    useEffect(() => {
        getSidebarData()


    }, []);

    const toggleArrow = () => {
        const element: any = document.getElementById('navbarToggleExternalContent');
        element.classList.remove('show');
    }



    const getSidebarData = () => {
        enviroment.get('sside_bar').then((response: any) => {
            if (response?.data?.length > 0) {
                let items = response?.data?.[0]?.acf
                setHeadeItems(items)
                getPathUploaded(items.user_image, 'image')
                getPathUploaded(items.file_url, 'file')
            }



        }).catch((error) => {

        })
    }

    const getPathUploaded = (id: number, type: string): any => {
        enviroment.get(`media/${id}`).then((response: any) => {
            if (response?.data) {
                if (type == 'image') { setProfile(response?.data.link) }
                else { setDownlaod(response?.data.link) }
            }
            else {
                if (type == 'image') { setProfile(Mainphoto) }
            }



        }).catch((error) => {
            // setIsLoading(false)
        })

    }



    const openResume = () => {
        window.open(download, '_blank')
    }



    return (
        <>




            {/* For Desktop */}
            <nav className="d-none d-xl-block">
                <div className="nav-bar ">
                    <ul className="ps-0">
                        <li>
                            <Link to="/" className="p-3">
                                <img src={PersonPath} alt="person" height={27} />
                                <span>About</span>
                            </Link>

                        </li>
                        <li>
                            <Link to="/resume" className="p-3">
                                <img src={Resume} alt="person" height={27} />
                                <span>Resume</span>
                            </Link>

                        </li>
                        <li>
                            <Link to="/portfolio" className="p-3">
                                <img src={Portfolio} alt="person" height={27} />
                                <span>Portfolio</span>
                            </Link>

                        </li>
                        {/* <li>
                            <Link to="/contact" className="p-3">
                                <img src={Contact} alt="person" height={27} />
                                <span>Contact</span>
                            </Link>

                        </li> */}
                    </ul>
                </div>
            </nav>

            {/* For Mobile */}
            <nav className="navbar mobile-nav d-block d-xl-none">
                <div className="container-fluid justify-content-end">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse mt-1" id="navbarToggleExternalContent" ref={child1}>
                    <div className="wrapper-nav p-2">
                        <ul className="ps-0">
                            <li>
                                <Link to="/" className="p-3" onClick={() => toggleArrow()}>
                                    <img src={PersonPath} alt="person" height={27} />
                                    <span>About</span>
                                </Link>

                            </li>
                            <li>
                                <Link to="/resume" className="p-3" onClick={() => toggleArrow()}>
                                    <img src={Resume} alt="person" height={27} />
                                    <span>Resume</span>
                                </Link>

                            </li>
                            <li>
                                <Link to="/portfolio" className="p-3" onClick={() => toggleArrow()}>
                                    <img src={Portfolio} alt="person" height={27} />
                                    <span>Portfolio</span>
                                </Link>

                            </li>
                            {/* <li>
                                <Link to="/contact" className="p-3" onClick={() => toggleArrow()}>
                                    <img src={Contact} alt="person" height={27} />
                                    <span>Contact</span>
                                </Link>

                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="navigation-container  d-flex justify-content-start align-items-center flex-column">

                <div className="navigation-container-image d-flex justify-content-center align-items-center">
                    <img src={profile} alt="main_image" />
                </div>


                <div className="navigation-container-text pt-2">
                    <h3 className="mb-0">Ritin Nijhawan</h3>
                    <p>{headerItems?.desgination}</p>
                </div>

                <div className="navigation-container-button">
                    <Button label="Download CV" onClick={() => openResume()} />
                </div>
            </div>








            <script src="https://cdn.linearicons.com/free/1.0.0/svgembedder.min.js"></script>
            <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
        </>

    )
}


export default Header;