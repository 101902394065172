import axios from 'axios';



// axios.defaults.baseURL = '';
// axios.defaults.headers.post['Content-Type'] = 'application/json';


export default axios.create({
    baseURL: `https://ritinnijhawan.in/api/wp-json/wp/v2/`
  });




// axios.interceptors.request.use(request => {
//     console.log(request);
//     // Edit request config
//     return request;
// }, error => {
//     console.log(error);
//     return Promise.reject(error);
// });

// axios.interceptors.response.use(response => {
//     console.log(response);
//     // Edit response config
//     return response;
// }, error => {
//     console.log(error);
//     return Promise.reject(error);
// });


