import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './layout/home/home';
import Header from './shared/header/header';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import About from './layout/about/about';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Portfolio from './layout/portfolio/portfolio';
import Resume from './layout/resume/resume';
import Contact from './layout/contact/contact';
import { HashRouter } from 'react-router-dom'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
  <div className='d-flex flex-column flex-xl-row overflow-hidden'>
    <div className='page-container d-flex flex-column flex-lg-row'>
      <Header > </Header>
      </div>
      <div className='routes-container'>
    
      <Routes>
      {/* <Route path="/" element={<Home />} /> */}
      {/* <Route path="/services" element={<Services />} /> */}
      <Route path="/" element={<About />} />
      <Route path='/portfolio' element={<Portfolio />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/contact" element={<Contact />} />
     
    </Routes>

    </div>
    </div>
  </HashRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
