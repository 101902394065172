import react, { Fragment, useEffect, useState } from "react";
import './about.scss';
import mypic from  '../../assets/images/my_photo.jpg';
import Textcontainer from "./text-container/text-container";
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';


function About(){

    const [commonContent, setCommonContent] =useState(Array)
    const [loadContent, setLoadContent] =useState(Array)

    const events = [
        { status: 'Master of Computer Application (M.C.A) ', university:'Guru Gobind Singh Indrapeastha University', from:'2015 to 2018'  },
        { status: 'Bachelor of Computer Application (B.C.A)', university:'Guru Gobind Singh Indrapeastha University', from:'2012 to 2015' },
        { status: '12th', university:'Central Board of Secondary Education ', from:'2011 to 2012'  },
        { status: '10th', university:'Central Board of Secondary Education ', from:'2009 to 2010'  }
    ];
    useEffect(() => {
        setCommonContent([
        {heading: "Age", value :28,id:1}, 
        {heading: "Residence ", value :"Delhi",id:2}, 
        {heading: "Address", value :"47 kewal park",id:3},  
        {heading: "e-mail", value :"ritin.nijhawan@gmail.com",id:4},
        {heading: "Phone", value :"+91-7838298988",id:5}])


      },[]);



      const customizedContent = (item:any) => {
        return (
            <Card title={item.status} subTitle={item.university}>
                <p>{item?.from}</p>    
            </Card>
        );
    };

 



    return(
    <div className="main-container py-3 px-2 p-lg-5">

        <div className="container">
            <h2 className="page-headings"> About <span>Me</span></h2>

            <div className="row mt-5">
                <div className="col-12 col-lg-7">

                        <p className="pe-3">I’ve been working in the industry since 2018, and I’ve worked on many different projects. My favorite
                            part about my job is seeing how far my skills can take me—it’s exciting to be able to help other people
                            solve problems by coming up with solutions that work.
                        </p>

                </div>

                <div className="col-12 col-lg-5">

                {commonContent.map((items:any, index:number)=><Textcontainer data={items}  key={index} />)}
                 
                    
                    </div>
            </div>

            <div className="row mt-5">
            <h2 className="page-headings pb-4"> Education</h2>
                <div className="col-12">
               <div className="d-none d-lg-block"><Timeline value={events}  content={(item) => customizedContent(item) } align="alternate"/></div>
               <div className="d-block d-lg-none"><Timeline value={events} content={(item) => customizedContent(item)} align="left"/></div>

                   

                </div>


            </div>


                
            


        </div>

    </div>
    )

}


export default About

